import { gql } from "@/__generated__";
import { addNewMerchantFirstInCache } from "@/pages/merchants/merchantsTable/cacheUtils";
import { useMutation } from "@apollo/client";

const SUBMIT_LEAD_MUTATION = gql(`
mutation submitLead($input: SubmitLeadInput!) {
	merchant {
		submitLead(input: $input) {
			success
			error {
				code
			}
			merchant {
				...addNewMerchantFirstInCache_Merchant
			}
		}
	}
}
`);

export function useSubmitLead() {
	const [submitLead, { loading, error, data }] = useMutation(SUBMIT_LEAD_MUTATION, {
		update(cache, { data }) {
			const submitLead = data?.merchant.submitLead;
			if (submitLead?.success && submitLead?.merchant) {
				addNewMerchantFirstInCache(cache, submitLead.merchant);
			}
		},
	});

	return {
		submitLead,
		loading,
		error,
		data: data || null,
	};
}
