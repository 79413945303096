import { type FragmentType, gql, useFragment } from "@/__generated__";
import { UseDeleteUser_UserFragmentDoc } from "@/__generated__/graphql";
import { useConfirmDesctructiveAction } from "@/hooks/useConfirmDesctructiveAction";
import { getDisplayName } from "@/pages/common/utils";
import { deleteUserFromCache } from "@/pages/users/userForm/cacheUtils";
import { useMutation } from "@apollo/client";
import { notifications } from "@mantine/notifications";

const UserForm_DeleteUser = gql(`
mutation deleteUser($userId: UUID!) {
	user {
		delete(userId: $userId) {
			success
			error {
				code
				message
			}
			userId 
		}
	}
}
`);

const useDeleteUser_User = gql(`
fragment useDeleteUser_User on User {
	id
	...getDisplayName_User	
}
`);

export function useDeleteUser() {
	const [deleteUser, { loading, error }] = useMutation(UserForm_DeleteUser, {
		update(cache, { data }) {
			const deleteUser = data?.user.delete;
			if (deleteUser?.success && deleteUser?.userId) {
				deleteUserFromCache(cache, deleteUser.userId);
			}
		},
	});
	const doDeleteUser = async (user: FragmentType<typeof useDeleteUser_User>) => {
		const userData = useFragment(UseDeleteUser_UserFragmentDoc, user);
		try {
			const { data } = await deleteUser({
				variables: {
					userId: userData.id,
				},
			});
			if (data?.user.delete?.success) {
				notifications.show({
					title: "User deleted",
					message: "User deleted successfully",
				});
			} else {
				notifications.show({
					title: "Failed to delete user",
					message: data?.user.delete?.error?.code,
					color: "red",
				});
			}
		} catch (e) {
			console.error(e);
			notifications.show({
				title: "Failed to delete user",
				message: `Failed with error: ${e}`,
				color: "red",
			});
		}
	};

	const { protectedAction, confirm } = useConfirmDesctructiveAction<FragmentType<typeof useDeleteUser_User>>({
		action: doDeleteUser,
		confirmText: (user) => getDisplayName(useFragment(UseDeleteUser_UserFragmentDoc, user)),
	});

	return {
		deleteUser: protectedAction,
		confirm,
		loading,
		error,
	};
}
