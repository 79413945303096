import type { MerchantsTable_MerchantFragment } from "@/__generated__/graphql";
import { getStatusIndicatorColor } from "@/pages/merchants/merchantsTable/getStatusIndicatorColor";
import { ColorSwatch, Group } from "@mantine/core";
import type { MRT_Cell } from "mantine-react-table";
import type { ReactNode } from "react";

type MerchantStatusCellProps = {
	renderedCellValue: ReactNode | number | string;
	cell: MRT_Cell<MerchantsTable_MerchantFragment, string>;
};

export function MerchantStatusCell({ renderedCellValue, cell }: MerchantStatusCellProps) {
	return (
		<Group gap="xs">
			<ColorSwatch withShadow={false} color={getStatusIndicatorColor(cell.row.original.status)} size={8} />
			<div>{renderedCellValue}</div>
		</Group>
	);
}
