import { firebaseAuth } from "@/auth/firebase";
import { getConfig } from "@/config";
import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { relayStylePagination } from "@apollo/client/utilities";

const config = getConfig();

const httpLink = createHttpLink({
	uri: ({ operationName }) => `${config.graphqlUri}/${operationName}`,
});

const authLink = setContext(async (_, { headers }) => {
	const token = await firebaseAuth.currentUser?.getIdToken();
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

export const apolloClient = new ApolloClient({
	link: from([authLink, httpLink]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					platforms: relayStylePagination(),
					merchants: relayStylePagination(),
				},
			},
		},
	}),
});
