import { gql } from "@/__generated__";
import { addNewPlatformFirstInCache } from "@/pages/platforms/createNewPlatform/cacheUtils";
import { useMutation } from "@apollo/client";

const CREATE_PLATFORM_MUTATION = gql(`
mutation createPlatform($input: CreatePlatformInput!) {
	platform {
		createPlatform(input: $input) {
			success
			error {
				code
			}
			platform {
				...addNewPlatformFirstInCache_Platform
			}
		}
	}
}
`);

export function useCreatePlatform() {
	const [createPlatform, { loading, error }] = useMutation(CREATE_PLATFORM_MUTATION, {
		update(cache, { data }) {
			const createPlatform = data?.platform.createPlatform;
			if (createPlatform?.success && createPlatform?.platform) {
				addNewPlatformFirstInCache(cache, createPlatform.platform);
			}
		},
	});

	return {
		createPlatform,
		loading,
		error,
	};
}
