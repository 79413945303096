import { gql } from "@/__generated__";
import { useMutation } from "@apollo/client";

const SAVE_PROVISIONING_DATA_MUTATION = gql(`
mutation saveProvisioningData($input: SaveProvisioningDataInput!) {
	merchant {
		saveProvisioningData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...Provisioning_Merchant
			}
		}
	}
}
`);
export function useSaveProvisioningData() {
	const [saveProvisioningData, { loading, error }] = useMutation(SAVE_PROVISIONING_DATA_MUTATION);
	return {
		saveProvisioningData,
		loading,
		error,
	};
}
