import { gql } from "@/__generated__";
import { addNewUserFirstInCache } from "@/pages/users/userForm/cacheUtils";
import { useMutation } from "@apollo/client";

const UserForm_CreateUser = gql(`
mutation createUser($input: CreateUserInput!) {
	user {
		create(input: $input) {
			success
			error {
				code
				message
			}
			user {
                ...addNewUserFirstInCache_User
			}
		}
	}
}
`);

const UserForm_UpdateUser = gql(`
mutation updateUser($input: UpdateUserInput!) {
	user {
		update(input: $input) {
			success
			error {
				code
				message
			}
			user {
                ...addNewUserFirstInCache_User
			}
		}
	}
}
`);

export function useSaveUserForm() {
	const [createUser, { loading, error }] = useMutation(UserForm_CreateUser, {
		update(cache, { data }) {
			const createUser = data?.user.create;
			if (createUser?.success && createUser?.user) {
				addNewUserFirstInCache(cache, createUser.user);
			}
		},
	});
	const [updateUser, { loading: updateLoading, error: updateError }] = useMutation(UserForm_UpdateUser);

	return {
		createUser,
		updateUser,
		loading: loading || updateLoading,
		error: error || updateError,
	};
}
