import { gql } from "@/__generated__";
import { addNewMerchantFirstInCache } from "@/pages/merchants/merchantsTable/cacheUtils";
import { useMutation } from "@apollo/client";

const SAVE_PROSPECTING_DATA_MUTATION = gql(`
mutation saveProspectingData($input: SaveProspectingDataInput!) {
	merchant {
		saveProspectingData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...Prospecting_Merchant
				...addNewMerchantFirstInCache_Merchant
			}
		}
	}
}
`);
export function useSaveProspectingData(existingMerhcantId?: string) {
	const [saveProspectingData, { loading, error }] = useMutation(SAVE_PROSPECTING_DATA_MUTATION, {
		update(cache, { data }) {
			if (existingMerhcantId) {
				// Since the merchant already exists apollo will handle the cache update automatically
				return;
			}
			const saveProspectingData = data?.merchant.saveProspectingData;
			if (saveProspectingData?.success && saveProspectingData?.merchant) {
				addNewMerchantFirstInCache(cache, saveProspectingData.merchant);
			}
		},
	});
	return {
		saveProspectingData,
		loading,
		error,
	};
}
