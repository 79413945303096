import {
	type MRT_ColumnDef,
	type MRT_Row,
	type MRT_RowData,
	type MRT_TableInstance,
	useMantineReactTable,
} from "mantine-react-table";
import type { ReactNode } from "react";

type FastlaneTableOptions<TData extends MRT_RowData> = {
	data: TData[];
	columns: MRT_ColumnDef<TData>[];
	searchPlaceholder?: string;
	isLoading?: boolean;
	initialColumnVisibility?: Record<string, boolean>;
	onRowClick?: (row: MRT_Row<TData>) => void;
	enableRowActions?: boolean;
	renderRowActionMenuItems?: (props: {
		renderedRowIndex?: number;
		row: MRT_Row<TData>;
		table: MRT_TableInstance<TData>;
	}) => ReactNode;
};

export function useFastlaneTable<TData extends MRT_RowData>(tableOptions: FastlaneTableOptions<TData>) {
	const onRowClick = (row: MRT_Row<TData>) =>
		tableOptions.onRowClick
			? () => {
					if (tableOptions.onRowClick) {
						tableOptions.onRowClick(row);
					}
				}
			: undefined;
	return useMantineReactTable({
		data: tableOptions.data,
		columns: tableOptions.columns,
		enablePagination: false,
		enableTableFooter: false,
		enableBottomToolbar: false,
		enableColumnFilters: false,
		positionGlobalFilter: "left",
		enableDensityToggle: false,
		layoutMode: "grid",
		mantineTableBodyRowProps: ({ row }) => ({
			onClick: onRowClick(row),
			style: {
				cursor: onRowClick(row) ? "pointer" : "default",
			},
		}),
		mantineSearchTextInputProps: {
			placeholder: tableOptions.searchPlaceholder,
		},
		mantinePaperProps: {
			withBorder: false,
			shadow: "none",
		},
		positionActionsColumn: "last",
		displayColumnDefOptions: {
			"mrt-row-actions": {
				header: "",
				size: 54,
				grow: false,
				mantineTableHeadCellProps: {
					align: "right",
				},
				mantineTableBodyCellProps: {
					align: "right",
				},
			},
		},
		initialState: {
			showGlobalFilter: true,
			columnVisibility: tableOptions.initialColumnVisibility || {},
		},
		state: {
			showSkeletons: tableOptions.isLoading,
		},
		enableRowActions: tableOptions.enableRowActions,
		renderRowActionMenuItems: tableOptions.renderRowActionMenuItems,
	});
}
