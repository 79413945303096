import { gql, useFragment } from "@/__generated__";
import { MerchantsTable_MerchantFragmentDoc } from "@/__generated__/graphql";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

export const MERCHANTS_QUERY = gql(`
query MerchantsTable_GetMerchants {
    merchants {
        edges {
            node {
                ...MerchantsTable_Merchant
            }
        }
    }
}
`);
export function useMerchantsForMerchantsTable() {
	const { data, loading, error } = useQuery(MERCHANTS_QUERY, {
		errorPolicy: "all",
	});
	const merchants = useMemo(
		() => data?.merchants?.edges.map((edge) => useFragment(MerchantsTable_MerchantFragmentDoc, edge.node)) || [],
		[data?.merchants?.edges],
	);
	return {
		merchants,
		loading,
		error,
	};
}
