import { gql } from "@/__generated__";
import type { MerchantsTable_MerchantFragment } from "@/__generated__/graphql";
import { AgeCell } from "@/components/table/AgeCell";
import { MoneyCell } from "@/components/table/MoneyCell";
import { TimestampCell } from "@/components/table/TimestampCell";
import { useFastlaneTable } from "@/components/table/useFastlaneTable";
import { getDisplayName, snakeToHumanReadable } from "@/pages/common/utils";
import { MerchantStatusCell } from "@/pages/merchants/merchantsTable/MerchantStatusCell";
import { useMerchantsForMerchantsTable } from "@/pages/merchants/merchantsTable/useMerchantsForMerchantsTable";
import { paths } from "@/paths";
import { Alert, Menu } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { type MRT_ColumnDef, type MRT_Row, MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { generatePath, useNavigate } from "react-router";

export const MerchantsTable_Merchant = gql(`
fragment MerchantsTable_Merchant on Merchant {
	id
	createdAt
	updatedAt
	legalEntityName
	stage
	status
	leadData {
		annualRevenue {
			amount
			currency
		}
		industry
		contactName
		contactEmail
		contactPhoneNumber
		contactJobTitle
		estimatedMonthlyPaymentVolume
		paymentMethodsToAccept
		hasNeedForRecurringPayments
		additionalRequirements
	}
	prospectingData {
		testing
	}
	platform {
		id
		name
	}
	createdBy {
		...getDisplayName_User
	}
	lastUpdatedBy {
		...getDisplayName_User
	}
}
`);

export function MerchantsTable() {
	const { merchants, loading, error } = useMerchantsForMerchantsTable();
	const navigate = useNavigate();

	const columns: Array<MRT_ColumnDef<MerchantsTable_MerchantFragment>> = useMemo(
		() => [
			{
				accessorKey: "legalEntityName",
				header: "Legal entity name",
				enableHiding: false,
				minSize: 250,
			},
			{
				accessorFn: (row) => snakeToHumanReadable(row.stage),
				id: "stage",
				grow: false,
				size: 170,
				header: "Stage",
			},
			{
				accessorFn: (row) => snakeToHumanReadable(row.status),
				id: "status",
				header: "Status",
				grow: false,
				size: 170,
				Cell: MerchantStatusCell,
			},
			{
				accessorFn: (m) => m.leadData?.annualRevenue,
				id: "leadData.annualRevenue",
				header: "Projected revenue",
				Cell: MoneyCell,
				size: 200,
				grow: false,
			},
			{
				accessorFn: (m) => m.platform?.name,
				id: "platform.name",
				header: "Platform",
				size: 200,
				grow: false,
			},
			{
				accessorKey: "createdAt",
				header: "Age",
				size: 130,
				grow: false,
				Cell: AgeCell,
			},
			{
				accessorFn: (p) => getDisplayName(p.createdBy),
				header: "Created by",
				id: "createdBy",
				size: 200,
				grow: false,
			},
			{
				accessorKey: "updatedAt",
				header: "Updated",
				size: 170,
				grow: false,
				Cell: TimestampCell,
			},
			{
				accessorFn: (p) => getDisplayName(p.lastUpdatedBy),
				header: "Last updated by",
				id: "lastUpdatedBy",
				size: 200,
				grow: false,
			},
			{
				accessorFn: (m) => m.leadData?.industry,
				id: "leadData.industry",
				header: "Industry",
			},
			{
				accessorFn: (m) => m.leadData?.contactName,
				id: "leadData.contactName",
				header: "Contact name",
			},
			{
				accessorFn: (m) => m.leadData?.contactEmail,
				id: "leadData.contactEmail",
				header: "Contact email",
			},
			{
				accessorFn: (m) => m.leadData?.contactPhoneNumber,
				id: "leadData.contactPhoneNumber",
				header: "Contact phone number",
			},
			{
				accessorFn: (m) => m.leadData?.contactJobTitle,
				id: "leadData.contactJobTitle",
				header: "Contact job title",
			},
			{
				accessorFn: (m) => m.leadData?.estimatedMonthlyPaymentVolume,
				id: "leadData.estimatedMonthlyPaymentVolume",
				header: "Estimated monthly payment volume",
			},
			{
				accessorFn: (m) => m.leadData?.paymentMethodsToAccept,
				id: "leadData.paymentMethodsToAccept",
				header: "Payment methods to accept",
			},
			{
				accessorFn: (m) => m.leadData?.hasNeedForRecurringPayments,
				id: "leadData.hasNeedForRecurringPayments",
				header: "Need for recurring payments",
			},
			{
				accessorFn: (m) => m.leadData?.additionalRequirements,
				id: "leadData.additionalRequirements",
				header: "Additional requirements",
			},
		],
		[],
	);
	const openOnboarding = (row: MRT_Row<MerchantsTable_MerchantFragment>) => {
		navigate(generatePath(paths.assistedOnboarding, { merchantId: row.original.id }));
	};
	const table = useFastlaneTable<MerchantsTable_MerchantFragment>({
		data: merchants,
		columns: columns,
		searchPlaceholder: "Search merchants",
		isLoading: loading,
		initialColumnVisibility: {
			createdBy: false,
			"leadData.industry": false,
			"leadData.contactName": false,
			"leadData.contactEmail": false,
			"leadData.contactPhoneNumber": false,
			"leadData.contactJobTitle": false,
			"leadData.estimatedMonthlyPaymentVolume": false,
			"leadData.paymentMethodsToAccept": false,
			"leadData.hasNeedForRecurringPayments": false,
			"leadData.additionalRequirements": false,
		},
		enableRowActions: true,
		renderRowActionMenuItems: ({ row }) => <Menu.Item onClick={() => openOnboarding(row)}>Open merchant</Menu.Item>,
	});
	return (
		<div>
			{error && (
				<Alert icon={<IconAlertTriangle />} title="Error" color="red">
					{error.message}
				</Alert>
			)}
			<MantineReactTable table={table} />
		</div>
	);
}
