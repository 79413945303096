/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nfragment AuthProvider_User on User {\n\tid\n\tfirstName\n\tlastName\n\temail\n\ttype\n\thasWriteAccess\n\tplatform {\n\t\tid\n\t}\n\tmerchant {\n\t\tid\n\t}\n}\n": types.AuthProvider_UserFragmentDoc,
    "\nfragment AuthProvider_Query on Query {\n\tme {\n\t\t...getDisplayName_User\n\t\t...AuthProvider_User\n\t}\n}\n": types.AuthProvider_QueryFragmentDoc,
    "\nquery AuthProvider_getLoggedInUser {\n\t...AuthProvider_Query\n}\n": types.AuthProvider_GetLoggedInUserDocument,
    "\nfragment SelectMerchant_Merchant on Merchant {\n\tid\n\tlegalEntityName\n}\n": types.SelectMerchant_MerchantFragmentDoc,
    "\nfragment SelectMerchant_Query on Query {\n    merchants {\n        edges {\n            node {\n                ...SelectMerchant_Merchant\n            }\n        }\n    }\n}\n": types.SelectMerchant_QueryFragmentDoc,
    "\nfragment SelectPlatform_Platform on Platform {\n\tid\n\tname\n}\n": types.SelectPlatform_PlatformFragmentDoc,
    "\nfragment SelectPlaform_Query on Query {\n    platforms {\n        edges {\n            node {\n                ...SelectPlatform_Platform\n            }\n        }\n    }\n}\n": types.SelectPlaform_QueryFragmentDoc,
    "\nfragment getDisplayName_User on User {\n\tid\n\tfirstName\n\tlastName\n\temail\n}\n": types.GetDisplayName_UserFragmentDoc,
    "\nfragment MerchantsTable_Merchant on Merchant {\n\tid\n\tcreatedAt\n\tupdatedAt\n\tlegalEntityName\n\tstage\n\tstatus\n\tleadData {\n\t\tannualRevenue {\n\t\t\tamount\n\t\t\tcurrency\n\t\t}\n\t\tindustry\n\t\tcontactName\n\t\tcontactEmail\n\t\tcontactPhoneNumber\n\t\tcontactJobTitle\n\t\testimatedMonthlyPaymentVolume\n\t\tpaymentMethodsToAccept\n\t\thasNeedForRecurringPayments\n\t\tadditionalRequirements\n\t}\n\tprospectingData {\n\t\ttesting\n\t}\n\tplatform {\n\t\tid\n\t\tname\n\t}\n\tcreatedBy {\n\t\t...getDisplayName_User\n\t}\n\tlastUpdatedBy {\n\t\t...getDisplayName_User\n\t}\n}\n": types.MerchantsTable_MerchantFragmentDoc,
    "\nfragment addNewMerchantFirstInCache_Merchant on Merchant {\n\t...MerchantsTable_Merchant\n\t...SelectMerchant_Merchant\n}\n": types.AddNewMerchantFirstInCache_MerchantFragmentDoc,
    "\nquery MerchantsTable_GetMerchants {\n    merchants {\n        edges {\n            node {\n                ...MerchantsTable_Merchant\n            }\n        }\n    }\n}\n": types.MerchantsTable_GetMerchantsDocument,
    "\nfragment AssistedOnboarding_Query on Query {\n    merchant(merchantId: $merchantId) @include(if: $merchantIdProvided) {\n        id\n\t\tstage\n\t\t...BusinessEligibility_Merchant\n\t\t...BusinessVerification_Merchant\n\t\t...Contracting_Merchant\n\t\t...Provisioning_Merchant\n\t\t...Live_Merchant\n\t\t...OnboardingStep_Merchant\n    }\n\t...Prospecting_Query\n}\n": types.AssistedOnboarding_QueryFragmentDoc,
    "\nfragment BusinessEligibility_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n": types.BusinessEligibility_MerchantFragmentDoc,
    "\nmutation saveBusinessEligibilityData($input: SaveBusinessEligibilityDataInput!) {\n\tmerchant {\n\t\tsaveBusinessEligibilityData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...BusinessEligibility_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n": types.SaveBusinessEligibilityDataDocument,
    "\nfragment BusinessVerification_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n": types.BusinessVerification_MerchantFragmentDoc,
    "\nmutation saveBusinessVerificationData($input: SaveBusinessVerificationDataInput!) {\n\tmerchant {\n\t\tsaveBusinessVerificationData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...BusinessVerification_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n": types.SaveBusinessVerificationDataDocument,
    "\nfragment OnboardingStep_Merchant on Merchant {\n\tid\n\t...MerchantNotes_Merchant\n}\n": types.OnboardingStep_MerchantFragmentDoc,
    "\nfragment Contracting_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n": types.Contracting_MerchantFragmentDoc,
    "\nmutation saveContractingData($input: SaveContractingDataInput!) {\n\tmerchant {\n\t\tsaveContractingData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...Contracting_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n": types.SaveContractingDataDocument,
    "\nfragment Live_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n": types.Live_MerchantFragmentDoc,
    "\nfragment MerchantNote_MerchantNote on MerchantNote {\n    id\n    message\n    createdAt\n    createdBy {\n        ...getDisplayName_User\n    }\n}\n": types.MerchantNote_MerchantNoteFragmentDoc,
    "\nfragment MerchantNotes_Merchant on Merchant{\n\tid\n\tnotes {\n\t\tid\n\t\t...MerchantNote_MerchantNote\n\t}\n}\n": types.MerchantNotes_MerchantFragmentDoc,
    "\nfragment addNoteFirstInCache_MerchantNote on MerchantNote {\n\t...MerchantNote_MerchantNote\n}\n": types.AddNoteFirstInCache_MerchantNoteFragmentDoc,
    "\nmutation createMerchantNote($input: CreateMerchantNoteInput!) {\n\tmerchantNote {\n\t\tcreate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n            note {\n\t\t\t\t...addNoteFirstInCache_MerchantNote\n            }\n\t\t}\n\t}\n}\n": types.CreateMerchantNoteDocument,
    "\nfragment Prospecting_Merchant on Merchant {\n\tid\n\tlegalEntityName\n\tstage\n\tstatus\n\tplatform {\n\t\tid\n\t}\n}\n": types.Prospecting_MerchantFragmentDoc,
    "\nfragment Prospecting_Query on Query {\n\t...SelectPlaform_Query\n\tmerchant(merchantId: $merchantId)  @include(if: $merchantIdProvided) {\n\t\t...Prospecting_Merchant\n\t}\n}\n": types.Prospecting_QueryFragmentDoc,
    "\nmutation saveProspectingData($input: SaveProspectingDataInput!) {\n\tmerchant {\n\t\tsaveProspectingData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...Prospecting_Merchant\n\t\t\t\t...addNewMerchantFirstInCache_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n": types.SaveProspectingDataDocument,
    "\nfragment Provisioning_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n": types.Provisioning_MerchantFragmentDoc,
    "\nmutation saveProvisioningData($input: SaveProvisioningDataInput!) {\n\tmerchant {\n\t\tsaveProvisioningData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...Provisioning_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n": types.SaveProvisioningDataDocument,
    "\nquery AssistedOnboarding_GetData($merchantId: UUID!, $merchantIdProvided: Boolean!) {\n    ...AssistedOnboarding_Query\n}\n": types.AssistedOnboarding_GetDataDocument,
    "\nfragment addNewPlatformFirstInCache_Platform on Platform {\n\t...PlatformsTable_Platform\n\t...SelectPlatform_Platform\n}\n": types.AddNewPlatformFirstInCache_PlatformFragmentDoc,
    "\nmutation createPlatform($input: CreatePlatformInput!) {\n\tplatform {\n\t\tcreatePlatform(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tplatform {\n\t\t\t\t...addNewPlatformFirstInCache_Platform\n\t\t\t}\n\t\t}\n\t}\n}\n": types.CreatePlatformDocument,
    "\nfragment PlatformsTable_Platform on Platform {\n\tid\n\tcreatedAt\n\tupdatedAt\n\tname\n\tcreatedBy {\n\t\t...getDisplayName_User\n\t}\n\tlastUpdatedBy {\n\t\t...getDisplayName_User\n\t}\n}\n": types.PlatformsTable_PlatformFragmentDoc,
    "\nfragment PlatformsTable_Query on Query {\n\tplatforms {\n\t\tedges {\n\t\t\tnode {\n\t\t\t\t...PlatformsTable_Platform\n\t\t\t}\n\t\t}\n\t}\n}\n": types.PlatformsTable_QueryFragmentDoc,
    "\nquery PlatformsTable_GetData {\n\t...PlatformsTable_Query\n}\n": types.PlatformsTable_GetDataDocument,
    "\nfragment SubmitLead_Query on Query {\n\tplatformExists(platformId: $platformId)\t\n}\n": types.SubmitLead_QueryFragmentDoc,
    "\nquery SubmitLead_getPlatformExists($platformId: UUID!) {\n    ...SubmitLead_Query\n}\n": types.SubmitLead_GetPlatformExistsDocument,
    "\nmutation submitLead($input: SubmitLeadInput!) {\n\tmerchant {\n\t\tsubmitLead(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\t...addNewMerchantFirstInCache_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n": types.SubmitLeadDocument,
    "\nmutation deleteUser($userId: UUID!) {\n\tuser {\n\t\tdelete(userId: $userId) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuserId \n\t\t}\n\t}\n}\n": types.DeleteUserDocument,
    "\nfragment useDeleteUser_User on User {\n\tid\n\t...getDisplayName_User\t\n}\n": types.UseDeleteUser_UserFragmentDoc,
    "\nfragment UserForm_User on User {\n\tid\n\temail\n\tfirstName\n\tlastName\n\ttype\n\thasWriteAccess\n    platform {\n        id\n        name\n    }\n    merchant {\n        id\n        legalEntityName\n    }\n    ...getDisplayName_User\n}\n": types.UserForm_UserFragmentDoc,
    "\nfragment UserForm_Query on Query {\n    user(userId: $userId) @include(if: $userIdProvided) {\n\t\t...UserForm_User\n    }\n\t...SelectPlaform_Query\n\t...SelectMerchant_Query\n}\n": types.UserForm_QueryFragmentDoc,
    "\nfragment addNewUserFirstInCache_User on User {\n    ...UsersTable_User\n    ...UserForm_User\n}\n": types.AddNewUserFirstInCache_UserFragmentDoc,
    "\nmutation createUser($input: CreateUserInput!) {\n\tuser {\n\t\tcreate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuser {\n                ...addNewUserFirstInCache_User\n\t\t\t}\n\t\t}\n\t}\n}\n": types.CreateUserDocument,
    "\nmutation updateUser($input: UpdateUserInput!) {\n\tuser {\n\t\tupdate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuser {\n                ...addNewUserFirstInCache_User\n\t\t\t}\n\t\t}\n\t}\n}\n": types.UpdateUserDocument,
    "\nquery UserForm_GetData($userId: UUID!, $userIdProvided: Boolean!) {\n    ...UserForm_Query\n}\n": types.UserForm_GetDataDocument,
    "\nfragment UsersTable_User on User {\n\tid\n\temail\n\ttype\n\thasWriteAccess\n    platform {\n        id\n        name\n    }\n    merchant {\n        id\n        legalEntityName\n    }\n    ...getDisplayName_User\n\t...useDeleteUser_User\n}\n": types.UsersTable_UserFragmentDoc,
    "\nfragment UsersTable_Query on Query {\n\tusers {\n        edges {\n            node {\n                ...UsersTable_User\n            }\n        }\n\t}\n}\n": types.UsersTable_QueryFragmentDoc,
    "\nquery UsersTable_GetData {\n\t...UsersTable_Query\n}\n": types.UsersTable_GetDataDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment AuthProvider_User on User {\n\tid\n\tfirstName\n\tlastName\n\temail\n\ttype\n\thasWriteAccess\n\tplatform {\n\t\tid\n\t}\n\tmerchant {\n\t\tid\n\t}\n}\n"): (typeof documents)["\nfragment AuthProvider_User on User {\n\tid\n\tfirstName\n\tlastName\n\temail\n\ttype\n\thasWriteAccess\n\tplatform {\n\t\tid\n\t}\n\tmerchant {\n\t\tid\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment AuthProvider_Query on Query {\n\tme {\n\t\t...getDisplayName_User\n\t\t...AuthProvider_User\n\t}\n}\n"): (typeof documents)["\nfragment AuthProvider_Query on Query {\n\tme {\n\t\t...getDisplayName_User\n\t\t...AuthProvider_User\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery AuthProvider_getLoggedInUser {\n\t...AuthProvider_Query\n}\n"): (typeof documents)["\nquery AuthProvider_getLoggedInUser {\n\t...AuthProvider_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment SelectMerchant_Merchant on Merchant {\n\tid\n\tlegalEntityName\n}\n"): (typeof documents)["\nfragment SelectMerchant_Merchant on Merchant {\n\tid\n\tlegalEntityName\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment SelectMerchant_Query on Query {\n    merchants {\n        edges {\n            node {\n                ...SelectMerchant_Merchant\n            }\n        }\n    }\n}\n"): (typeof documents)["\nfragment SelectMerchant_Query on Query {\n    merchants {\n        edges {\n            node {\n                ...SelectMerchant_Merchant\n            }\n        }\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment SelectPlatform_Platform on Platform {\n\tid\n\tname\n}\n"): (typeof documents)["\nfragment SelectPlatform_Platform on Platform {\n\tid\n\tname\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment SelectPlaform_Query on Query {\n    platforms {\n        edges {\n            node {\n                ...SelectPlatform_Platform\n            }\n        }\n    }\n}\n"): (typeof documents)["\nfragment SelectPlaform_Query on Query {\n    platforms {\n        edges {\n            node {\n                ...SelectPlatform_Platform\n            }\n        }\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment getDisplayName_User on User {\n\tid\n\tfirstName\n\tlastName\n\temail\n}\n"): (typeof documents)["\nfragment getDisplayName_User on User {\n\tid\n\tfirstName\n\tlastName\n\temail\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment MerchantsTable_Merchant on Merchant {\n\tid\n\tcreatedAt\n\tupdatedAt\n\tlegalEntityName\n\tstage\n\tstatus\n\tleadData {\n\t\tannualRevenue {\n\t\t\tamount\n\t\t\tcurrency\n\t\t}\n\t\tindustry\n\t\tcontactName\n\t\tcontactEmail\n\t\tcontactPhoneNumber\n\t\tcontactJobTitle\n\t\testimatedMonthlyPaymentVolume\n\t\tpaymentMethodsToAccept\n\t\thasNeedForRecurringPayments\n\t\tadditionalRequirements\n\t}\n\tprospectingData {\n\t\ttesting\n\t}\n\tplatform {\n\t\tid\n\t\tname\n\t}\n\tcreatedBy {\n\t\t...getDisplayName_User\n\t}\n\tlastUpdatedBy {\n\t\t...getDisplayName_User\n\t}\n}\n"): (typeof documents)["\nfragment MerchantsTable_Merchant on Merchant {\n\tid\n\tcreatedAt\n\tupdatedAt\n\tlegalEntityName\n\tstage\n\tstatus\n\tleadData {\n\t\tannualRevenue {\n\t\t\tamount\n\t\t\tcurrency\n\t\t}\n\t\tindustry\n\t\tcontactName\n\t\tcontactEmail\n\t\tcontactPhoneNumber\n\t\tcontactJobTitle\n\t\testimatedMonthlyPaymentVolume\n\t\tpaymentMethodsToAccept\n\t\thasNeedForRecurringPayments\n\t\tadditionalRequirements\n\t}\n\tprospectingData {\n\t\ttesting\n\t}\n\tplatform {\n\t\tid\n\t\tname\n\t}\n\tcreatedBy {\n\t\t...getDisplayName_User\n\t}\n\tlastUpdatedBy {\n\t\t...getDisplayName_User\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment addNewMerchantFirstInCache_Merchant on Merchant {\n\t...MerchantsTable_Merchant\n\t...SelectMerchant_Merchant\n}\n"): (typeof documents)["\nfragment addNewMerchantFirstInCache_Merchant on Merchant {\n\t...MerchantsTable_Merchant\n\t...SelectMerchant_Merchant\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery MerchantsTable_GetMerchants {\n    merchants {\n        edges {\n            node {\n                ...MerchantsTable_Merchant\n            }\n        }\n    }\n}\n"): (typeof documents)["\nquery MerchantsTable_GetMerchants {\n    merchants {\n        edges {\n            node {\n                ...MerchantsTable_Merchant\n            }\n        }\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment AssistedOnboarding_Query on Query {\n    merchant(merchantId: $merchantId) @include(if: $merchantIdProvided) {\n        id\n\t\tstage\n\t\t...BusinessEligibility_Merchant\n\t\t...BusinessVerification_Merchant\n\t\t...Contracting_Merchant\n\t\t...Provisioning_Merchant\n\t\t...Live_Merchant\n\t\t...OnboardingStep_Merchant\n    }\n\t...Prospecting_Query\n}\n"): (typeof documents)["\nfragment AssistedOnboarding_Query on Query {\n    merchant(merchantId: $merchantId) @include(if: $merchantIdProvided) {\n        id\n\t\tstage\n\t\t...BusinessEligibility_Merchant\n\t\t...BusinessVerification_Merchant\n\t\t...Contracting_Merchant\n\t\t...Provisioning_Merchant\n\t\t...Live_Merchant\n\t\t...OnboardingStep_Merchant\n    }\n\t...Prospecting_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment BusinessEligibility_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"): (typeof documents)["\nfragment BusinessEligibility_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation saveBusinessEligibilityData($input: SaveBusinessEligibilityDataInput!) {\n\tmerchant {\n\t\tsaveBusinessEligibilityData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...BusinessEligibility_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation saveBusinessEligibilityData($input: SaveBusinessEligibilityDataInput!) {\n\tmerchant {\n\t\tsaveBusinessEligibilityData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...BusinessEligibility_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment BusinessVerification_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"): (typeof documents)["\nfragment BusinessVerification_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation saveBusinessVerificationData($input: SaveBusinessVerificationDataInput!) {\n\tmerchant {\n\t\tsaveBusinessVerificationData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...BusinessVerification_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation saveBusinessVerificationData($input: SaveBusinessVerificationDataInput!) {\n\tmerchant {\n\t\tsaveBusinessVerificationData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...BusinessVerification_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment OnboardingStep_Merchant on Merchant {\n\tid\n\t...MerchantNotes_Merchant\n}\n"): (typeof documents)["\nfragment OnboardingStep_Merchant on Merchant {\n\tid\n\t...MerchantNotes_Merchant\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment Contracting_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"): (typeof documents)["\nfragment Contracting_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation saveContractingData($input: SaveContractingDataInput!) {\n\tmerchant {\n\t\tsaveContractingData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...Contracting_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation saveContractingData($input: SaveContractingDataInput!) {\n\tmerchant {\n\t\tsaveContractingData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...Contracting_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment Live_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"): (typeof documents)["\nfragment Live_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment MerchantNote_MerchantNote on MerchantNote {\n    id\n    message\n    createdAt\n    createdBy {\n        ...getDisplayName_User\n    }\n}\n"): (typeof documents)["\nfragment MerchantNote_MerchantNote on MerchantNote {\n    id\n    message\n    createdAt\n    createdBy {\n        ...getDisplayName_User\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment MerchantNotes_Merchant on Merchant{\n\tid\n\tnotes {\n\t\tid\n\t\t...MerchantNote_MerchantNote\n\t}\n}\n"): (typeof documents)["\nfragment MerchantNotes_Merchant on Merchant{\n\tid\n\tnotes {\n\t\tid\n\t\t...MerchantNote_MerchantNote\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment addNoteFirstInCache_MerchantNote on MerchantNote {\n\t...MerchantNote_MerchantNote\n}\n"): (typeof documents)["\nfragment addNoteFirstInCache_MerchantNote on MerchantNote {\n\t...MerchantNote_MerchantNote\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createMerchantNote($input: CreateMerchantNoteInput!) {\n\tmerchantNote {\n\t\tcreate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n            note {\n\t\t\t\t...addNoteFirstInCache_MerchantNote\n            }\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation createMerchantNote($input: CreateMerchantNoteInput!) {\n\tmerchantNote {\n\t\tcreate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n            note {\n\t\t\t\t...addNoteFirstInCache_MerchantNote\n            }\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment Prospecting_Merchant on Merchant {\n\tid\n\tlegalEntityName\n\tstage\n\tstatus\n\tplatform {\n\t\tid\n\t}\n}\n"): (typeof documents)["\nfragment Prospecting_Merchant on Merchant {\n\tid\n\tlegalEntityName\n\tstage\n\tstatus\n\tplatform {\n\t\tid\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment Prospecting_Query on Query {\n\t...SelectPlaform_Query\n\tmerchant(merchantId: $merchantId)  @include(if: $merchantIdProvided) {\n\t\t...Prospecting_Merchant\n\t}\n}\n"): (typeof documents)["\nfragment Prospecting_Query on Query {\n\t...SelectPlaform_Query\n\tmerchant(merchantId: $merchantId)  @include(if: $merchantIdProvided) {\n\t\t...Prospecting_Merchant\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation saveProspectingData($input: SaveProspectingDataInput!) {\n\tmerchant {\n\t\tsaveProspectingData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...Prospecting_Merchant\n\t\t\t\t...addNewMerchantFirstInCache_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation saveProspectingData($input: SaveProspectingDataInput!) {\n\tmerchant {\n\t\tsaveProspectingData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...Prospecting_Merchant\n\t\t\t\t...addNewMerchantFirstInCache_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment Provisioning_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"): (typeof documents)["\nfragment Provisioning_Merchant on Merchant {\n\tid\n\tstage\n\tstatus\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation saveProvisioningData($input: SaveProvisioningDataInput!) {\n\tmerchant {\n\t\tsaveProvisioningData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...Provisioning_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation saveProvisioningData($input: SaveProvisioningDataInput!) {\n\tmerchant {\n\t\tsaveProvisioningData(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\tid\n\t\t\t\t...MerchantsTable_Merchant\n\t\t\t\t...Provisioning_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery AssistedOnboarding_GetData($merchantId: UUID!, $merchantIdProvided: Boolean!) {\n    ...AssistedOnboarding_Query\n}\n"): (typeof documents)["\nquery AssistedOnboarding_GetData($merchantId: UUID!, $merchantIdProvided: Boolean!) {\n    ...AssistedOnboarding_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment addNewPlatformFirstInCache_Platform on Platform {\n\t...PlatformsTable_Platform\n\t...SelectPlatform_Platform\n}\n"): (typeof documents)["\nfragment addNewPlatformFirstInCache_Platform on Platform {\n\t...PlatformsTable_Platform\n\t...SelectPlatform_Platform\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createPlatform($input: CreatePlatformInput!) {\n\tplatform {\n\t\tcreatePlatform(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tplatform {\n\t\t\t\t...addNewPlatformFirstInCache_Platform\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation createPlatform($input: CreatePlatformInput!) {\n\tplatform {\n\t\tcreatePlatform(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tplatform {\n\t\t\t\t...addNewPlatformFirstInCache_Platform\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment PlatformsTable_Platform on Platform {\n\tid\n\tcreatedAt\n\tupdatedAt\n\tname\n\tcreatedBy {\n\t\t...getDisplayName_User\n\t}\n\tlastUpdatedBy {\n\t\t...getDisplayName_User\n\t}\n}\n"): (typeof documents)["\nfragment PlatformsTable_Platform on Platform {\n\tid\n\tcreatedAt\n\tupdatedAt\n\tname\n\tcreatedBy {\n\t\t...getDisplayName_User\n\t}\n\tlastUpdatedBy {\n\t\t...getDisplayName_User\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment PlatformsTable_Query on Query {\n\tplatforms {\n\t\tedges {\n\t\t\tnode {\n\t\t\t\t...PlatformsTable_Platform\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nfragment PlatformsTable_Query on Query {\n\tplatforms {\n\t\tedges {\n\t\t\tnode {\n\t\t\t\t...PlatformsTable_Platform\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery PlatformsTable_GetData {\n\t...PlatformsTable_Query\n}\n"): (typeof documents)["\nquery PlatformsTable_GetData {\n\t...PlatformsTable_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment SubmitLead_Query on Query {\n\tplatformExists(platformId: $platformId)\t\n}\n"): (typeof documents)["\nfragment SubmitLead_Query on Query {\n\tplatformExists(platformId: $platformId)\t\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery SubmitLead_getPlatformExists($platformId: UUID!) {\n    ...SubmitLead_Query\n}\n"): (typeof documents)["\nquery SubmitLead_getPlatformExists($platformId: UUID!) {\n    ...SubmitLead_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation submitLead($input: SubmitLeadInput!) {\n\tmerchant {\n\t\tsubmitLead(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\t...addNewMerchantFirstInCache_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation submitLead($input: SubmitLeadInput!) {\n\tmerchant {\n\t\tsubmitLead(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t}\n\t\t\tmerchant {\n\t\t\t\t...addNewMerchantFirstInCache_Merchant\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation deleteUser($userId: UUID!) {\n\tuser {\n\t\tdelete(userId: $userId) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuserId \n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation deleteUser($userId: UUID!) {\n\tuser {\n\t\tdelete(userId: $userId) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuserId \n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment useDeleteUser_User on User {\n\tid\n\t...getDisplayName_User\t\n}\n"): (typeof documents)["\nfragment useDeleteUser_User on User {\n\tid\n\t...getDisplayName_User\t\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UserForm_User on User {\n\tid\n\temail\n\tfirstName\n\tlastName\n\ttype\n\thasWriteAccess\n    platform {\n        id\n        name\n    }\n    merchant {\n        id\n        legalEntityName\n    }\n    ...getDisplayName_User\n}\n"): (typeof documents)["\nfragment UserForm_User on User {\n\tid\n\temail\n\tfirstName\n\tlastName\n\ttype\n\thasWriteAccess\n    platform {\n        id\n        name\n    }\n    merchant {\n        id\n        legalEntityName\n    }\n    ...getDisplayName_User\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UserForm_Query on Query {\n    user(userId: $userId) @include(if: $userIdProvided) {\n\t\t...UserForm_User\n    }\n\t...SelectPlaform_Query\n\t...SelectMerchant_Query\n}\n"): (typeof documents)["\nfragment UserForm_Query on Query {\n    user(userId: $userId) @include(if: $userIdProvided) {\n\t\t...UserForm_User\n    }\n\t...SelectPlaform_Query\n\t...SelectMerchant_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment addNewUserFirstInCache_User on User {\n    ...UsersTable_User\n    ...UserForm_User\n}\n"): (typeof documents)["\nfragment addNewUserFirstInCache_User on User {\n    ...UsersTable_User\n    ...UserForm_User\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createUser($input: CreateUserInput!) {\n\tuser {\n\t\tcreate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuser {\n                ...addNewUserFirstInCache_User\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation createUser($input: CreateUserInput!) {\n\tuser {\n\t\tcreate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuser {\n                ...addNewUserFirstInCache_User\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateUser($input: UpdateUserInput!) {\n\tuser {\n\t\tupdate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuser {\n                ...addNewUserFirstInCache_User\n\t\t\t}\n\t\t}\n\t}\n}\n"): (typeof documents)["\nmutation updateUser($input: UpdateUserInput!) {\n\tuser {\n\t\tupdate(input: $input) {\n\t\t\tsuccess\n\t\t\terror {\n\t\t\t\tcode\n\t\t\t\tmessage\n\t\t\t}\n\t\t\tuser {\n                ...addNewUserFirstInCache_User\n\t\t\t}\n\t\t}\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery UserForm_GetData($userId: UUID!, $userIdProvided: Boolean!) {\n    ...UserForm_Query\n}\n"): (typeof documents)["\nquery UserForm_GetData($userId: UUID!, $userIdProvided: Boolean!) {\n    ...UserForm_Query\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UsersTable_User on User {\n\tid\n\temail\n\ttype\n\thasWriteAccess\n    platform {\n        id\n        name\n    }\n    merchant {\n        id\n        legalEntityName\n    }\n    ...getDisplayName_User\n\t...useDeleteUser_User\n}\n"): (typeof documents)["\nfragment UsersTable_User on User {\n\tid\n\temail\n\ttype\n\thasWriteAccess\n    platform {\n        id\n        name\n    }\n    merchant {\n        id\n        legalEntityName\n    }\n    ...getDisplayName_User\n\t...useDeleteUser_User\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UsersTable_Query on Query {\n\tusers {\n        edges {\n            node {\n                ...UsersTable_User\n            }\n        }\n\t}\n}\n"): (typeof documents)["\nfragment UsersTable_Query on Query {\n\tusers {\n        edges {\n            node {\n                ...UsersTable_User\n            }\n        }\n\t}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery UsersTable_GetData {\n\t...UsersTable_Query\n}\n"): (typeof documents)["\nquery UsersTable_GetData {\n\t...UsersTable_Query\n}\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;