import { gql } from "@/__generated__";
import { useMutation } from "@apollo/client";

const SAVE_CONTRACTING_DATA_MUTATION = gql(`
mutation saveContractingData($input: SaveContractingDataInput!) {
	merchant {
		saveContractingData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...Contracting_Merchant
			}
		}
	}
}
`);
export function useSaveContractingData() {
	const [saveContractingData, { loading, error }] = useMutation(SAVE_CONTRACTING_DATA_MUTATION);
	return {
		saveContractingData,
		loading,
		error,
	};
}
