import { type FragmentType, gql, useFragment } from "@/__generated__";
import { AddNewMerchantFirstInCache_MerchantFragmentDoc } from "@/__generated__/graphql";
import type { ApolloCache } from "@apollo/client";

const addNewMerchantFirstInCache_Merchant = gql(`
fragment addNewMerchantFirstInCache_Merchant on Merchant {
	...MerchantsTable_Merchant
	...SelectMerchant_Merchant
}
`);

export function addNewMerchantFirstInCache(
	cache: ApolloCache<FragmentType<typeof addNewMerchantFirstInCache_Merchant>>,
	merchant: FragmentType<typeof addNewMerchantFirstInCache_Merchant>,
) {
	const rootQueryId = cache.identify({ __typename: "Query" });
	const merchantData = useFragment(AddNewMerchantFirstInCache_MerchantFragmentDoc, merchant);
	cache.modify({
		id: rootQueryId,
		fields: {
			merchants: (existingMerchants) => {
				if (!existingMerchants) {
					return { edges: { node: merchantData } };
				}
				return {
					edges: [{ node: merchantData }, ...existingMerchants.edges],
				};
			},
		},
	});
}
